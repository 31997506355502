import React from "react";
import { slide as Menu } from "react-burger-menu";
import { Link } from "react-router-dom";

export default (props) => {
  return (
    // Pass on our props
    <Menu {...props}>
      <ul>
      <Link to="/" className="link">
            <li>HOME</li>
          </Link>
          <li>
            <div class="dropdown">
              <li class="dropbtn">About ISA HCB</li>
              {/* <div class="dropdown-content">
                <a href="#">Link 1</a>
                <a href="#">Link 2</a>
                <a href="#">Link 3</a>
              </div> */}
            </div>
          </li>
          <Link to="/members" className="link">
            <li>Members</li>
          </Link>
          <li>Resources</li>
          <Link to="/programs" className="link">
            <li>Programes</li>
          </Link>
          <Link to="/gallery" className="link">
            <li>Gallery</li>
          </Link>
          <Link to="/contact" className="link">
            <li>Contact</li>
          </Link>
      </ul>
    
    </Menu>
  );
};
