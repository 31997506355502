import React from "react";
import "./Programs.css";
function Programs() {
  return (
    <>
      <div className="program-container">
        <div className="program-main-container">
          <div className="event-container">
            <div className="event-content ">
              <div>
                <img className="event-image" src="assets/Capture.PNG" />
              </div>
              <div wi>
                <p className="event-content-p">
                  World Anaesthesia Day was celebrated on 15th October 2022 by
                  Haldwani Society of Anaesthesiologists in association with
                  Department of Anaesthesiology, Critical Care, Pain and
                  Palliative Medicine. Opening speech and briefing of History of
                  Anaesthesiawas given by Dr. Geeta Bhandari (Proff and Head of
                  DepartmentAnaesthesia, GMC Haldwani). Dr. Rahul Saxena (AP)
                  and Post graduate students gave presentation on ECMO. Also,
                  officials from Paramount Sigmoid Ltd. Did a demonstration on
                  ‘ICU Beds’.
                </p>
              </div>
            </div>
            <div className="event-content">
              <div>
                <img className="event-image" src="assets/Capture1.PNG" />
              </div>
              <div>
                <p className="event-content-p">
                  <p className="event-content-p">
                    CME was organised by Haldwani Society of Anaesthesiologists
                    (HSA) and Department of Anaesthesiology, Critical Care, Pain
                    and Palliative Medicine in conference hall of GMC, Haldwani
                    on 10.11.2022. It was attended by department faculty, post
                    graduate students and HSA members.It was a demonstration on
                    how to monitor patient via software hybrid mode/
                    audio-visual mode. Also, brief overview of future role of
                    robotic technology was discussed
                  </p>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Programs;
