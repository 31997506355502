import React from "react";

function Footer() {
  return (
    <>
      {/* <div className="container my-5" style={{ maxWidth: "100%" }}> */}
      <footer
        className="text-center text-lg-start text-white"
        style={{ backgroundColor: "#0d374c" }}
      >
        <div className="container p-4 pb-0">
          <section className="">
            <div className="row">
              {/* <div className="col-md-3 col-lg-3 col-xl-3 mx-auto mt-3">
                <div>
                  <img className="logo" src="assets/logo (1).png" />
                </div>
                <p>
                  Haldwani Society of Anaesthesiologists (HSA) and Department of
                  Anaesthesiology, Critical Care, Pain and Palliative Medicine
                  in conference hall of GMC, Haldwani on 10.11.2022. It was
                  attended by department faculty, post graduate students and HSA
                  members
                </p>
              </div> */}

              <hr className="w-100 clearfix d-md-none" />

              <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mt-3">
                <h6 className="text-uppercase mb-4 font-weight-bold">Links</h6>
                <p>
                  <a className="text-white">Home</a>
                </p>
                <p>
                  <a className="text-white">About</a>
                </p>
                <p>
                  <a className="text-white">Members</a>
                </p>
                <p>
                  <a className="text-white">Resources</a>
                </p>
              </div>

              <hr className="w-100 clearfix d-md-none" />

              <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mt-3">
                <h6 className="text-uppercase mb-4 font-weight-bold">
                  Useful links
                </h6>

                <p>
                  <a className="text-white">Programes</a>
                </p>
                <p>
                  <a className="text-white">Gallery</a>
                </p>
                <p>
                  <a className="text-white">Contact</a>
                </p>
              </div>

              <hr className="w-100 clearfix d-md-none" />

              <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mt-3">
                <h6 className="text-uppercase mb-4 font-weight-bold">
                  Contact
                </h6>
                <p>
                  <i className="fas fa-home mr-3"></i>Haldwani Uttarakhand
                </p>
                <p>
                  <i className="fas fa-envelope mr-3"></i>{" "}
                  devbhoomihsa@gmail.com
                </p>
                <p>
                  <i className="fas fa-phone mr-3"></i> 282824
                </p>
                <p>
                  <i className="fas fa-print mr-3"></i> 05946-228393
                </p>
              </div>
            </div>
          </section>

          <hr className="my-3" />

          <section className="p-3 pt-0">
            <div className="row d-flex align-items-center">
              <div className="col-md-7 col-lg-8 text-center text-md-start">
                <div className="p-3">
                  Copyright ©2023 all rights reserved Haldwani Society of
                  Anesthesiologist
                  {/* <a className="text-white" href="https://mdbootstrap.com/">
                    MDBootstrap.com
                  </a> */}
                </div>
              </div>

              <div className="col-md-5 col-lg-4 ml-lg-0 text-center text-md-end">
                <a
                  className="btn btn-outline-light btn-floating m-1"
                  class="text-white"
                  role="button"
                >
                  <i className="fab fa-facebook-f"></i>
                </a>

                <a
                  className="btn btn-outline-light btn-floating m-1"
                  class="text-white"
                  role="button"
                >
                  <i className="fab fa-twitter"></i>
                </a>

                <a
                  className="btn btn-outline-light btn-floating m-1"
                  class="text-white"
                  role="button"
                >
                  <i className="fab fa-google"></i>
                </a>

                <a
                  className="btn btn-outline-light btn-floating m-1"
                  class="text-white"
                  role="button"
                >
                  <i className="fab fa-instagram"></i>
                </a>
              </div>
            </div>
          </section>
        </div>
      </footer>
      {/* </div> */}
    </>
  );
}

export default Footer;
