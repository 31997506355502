import "./Gallery.css";
const Gallery = () => {
  return (
    <>
      <div className="gallery-container">
        <div className="gallery-main-container">
          <div className="gallery-image-container">
            <div className="gallery-image">
              <img
                className="gallery-image"
                src="./assets/IMG-20230514-WA0136.jpg"
              />
            </div>
            <div className="gallery-image">
              <img
                className="gallery-image"
                src="./assets/IMG-20230514-WA0139.jpg"
              />
            </div>
            <div className="gallery-image">
              <img
                className="gallery-image"
                src="./assets/IMG-20230515-WA0053.jpg"
              />
            </div>
            <div className="gallery-image">
              <img
                className="gallery-image"
                src="./assets/IMG-20230515-WA0094.jpg"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Gallery;
