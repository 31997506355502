const MembersData = [
  //   {
  //     slNo: "SL No.",
  //     Column2: "ISA No",
  //     Column3: "Name",
  //     Column4: "Email ID",
  //     Column5: "Mobile",
  //     Column6: "City Branch",
  //     Column7: "State Branch",
  //     Column8: "Member Type",
  //     Column9: "Status",
  //     Column10: "Current Address",
  //     Column11: "Remarks",
  //   },
  {
    slNo: 162,
    Column2: "A0361",
    Column3: "DR. AJAY KUMAR SINHA",
    Column4: "drajayksinha@yahoo.co.in",
    Column5: 9412906901,
    Column6: "HALDWANI",
    Column7: "UTTARAKHAND",
    Column8: "LM",
    Column9: "ACTIVE",
    Column10:
      "DoorNo/House No/Flat No/Name : ASST. PROFESSOR , Lane/Street/Village : DEPT. OF ANAESTHESIOLOGY , Area/Mandal/Land Mark : UPHT MEDICAL COLLEGE , State : UTTARAKHAND , District : NAINITAL , City : HALDWANI,NAINITAL , Pincode : 263139 , Post Office : ",
    Column11: "mob no.-9897672301",
  },
  {
    slNo: 163,
    Column2: "A0701",
    Column3: "DR. AMLA SHAH",
    Column4: "amlashah11@yahoo.com",
    Column5: 9412086554,
    Column6: "HALDWANI",
    Column7: "UTTARAKHAND",
    Column8: "LM",
    Column9: "ACTIVE",
    Column10:
      "DoorNo/House No/Flat No/Name : S.T.M.FORESH HOSPITAL , Lane/Street/Village : PO BOX -20 , Area/Mandal/Land Mark : RAMPUR ROAD , State : UTTARAKHAND , District : NAINITAL , City : HALDWANI , Pincode : 263139 , Post Office : ",
  },
  {
    slNo: 164,
    Column2: "A1365",
    Column3: "ATUL K JOSHI",
    Column4: "atul_deepmala@hotmail.com",
    Column5: 9837047486,
    Column6: "HALDWANI",
    Column7: "UTTARAKHAND",
    Column8: "LM",
    Column9: "ACTIVE",
    Column10:
      "DoorNo/House No/Flat No/Name : CHIRANJIV HOSPITAL , Lane/Street/Village : D1D2 CIVIL LINES, DOCTORS LANE , Area/Mandal/Land Mark : DIST. U. S. NAGAR , State : , District : , City : HALDWANI , Pincode : 263139 , Post Office : ",
  },
  {
    slNo: 165,
    Column2: "B1003",
    Column3: "DR. DINESH CHANDRA BHATT",
    Column4: "dineshbhatt.dr@gmail.com",
    Column5: 9412351688,
    Column6: "HALDWANI",
    Column7: "UTTARAKHAND",
    Column8: "LM",
    Column9: "ACTIVE",
    Column10:
      "DoorNo/House No/Flat No/Name : 5/286, BANSHI KUNJ, , Lane/Street/Village : CANAL ROAD, TIVONIA, , Area/Mandal/Land Mark : DIST. NAINITAL , State : , District : , City : HALDWANI , Pincode : , Post Office : ",
  },
  {
    slNo: 166,
    Column2: "C0619",
    Column3: "DR. SHARMA YOGESH CHANDRA",
    Column4: "drycsharma1953@gmail.com",
    Column5: 9412085633,
    Column6: "HALDWANI",
    Column7: "UTTARAKHAND",
    Column8: "LM",
    Column9: "ACTIVE",
    Column10:
      "DoorNo/House No/Flat No/Name : SHANKAR HOSPITAL, , Lane/Street/Village : RAMPUR ROAD, , Area/Mandal/Land Mark : HALDWANI , State : UTTARAKHAND , District : NANITAL , City : NAINITAL , Pincode : 263139 , Post Office : ",
    Column11: "Retired",
  },
  {
    slNo: 167,
    Column2: "G0356",
    Column3: "DR COL GYAN CHAND",
    Column4: "gyan.chnd@gmail.com",
    Column5: 9412944731,
    Column6: "HALDWANI",
    Column7: "UTTARAKHAND",
    Column8: "LM",
    Column9: "ACTIVE",
    Column10:
      "DoorNo/House No/Flat No/Name : MITRA NIWAS, AMRAWATI COLONY-3 , Lane/Street/Village : AMRAWATI COLONY - III, , Area/Mandal/Land Mark : TALLI BAMORI, HALDWANI , State : UTTARAKHAND , District : NAINITAL. , City : HALDWANI , Pincode : 263139 , Post Office : HALDWANI",
    Column11: "Retired",
  },
  {
    slNo: 168,
    Column2: "G0589",
    Column3: "DR. GEETA BHANDARI",
    Column4: "bhandari_geetantl@rediffmail.com",
    Column5: 8958267145,
    Column6: "HALDWANI",
    Column7: "UTTARAKHAND",
    Column8: "LM",
    Column9: "ACTIVE",
    Column10:
      "DoorNo/House No/Flat No/Name : KAMAL KUNJ, RAMPUR ROAD , Lane/Street/Village : NORTH-MANPUR, HALDWANI , Area/Mandal/Land Mark : DIST. NAINITAL , State : UTTARAKHAND , District : NAINITAL , City : HALDWANI , Pincode : 263139 , Post Office : ",
    Column11:
      "mob no.-8650655556 ; Address-Type 4, A1 Dr STM Govt Hospital Campus GMC Haldwani",
  },
  {
    slNo: 169,
    Column2: "G0887",
    Column3: "VINOD KUMAR GARKOTI",
    Column4: "vinodkgarkoti@gmail.com",
    Column5: 9760370371,
    Column6: "HALDWANI",
    Column7: "UTTARAKHAND",
    Column8: "LM",
    Column9: "ACTIVE",
    Column10:
      "DoorNo/House No/Flat No/Name : GRAPES VILLA RAMGARH ROAD , Lane/Street/Village : BHOWALI (NAINITAL) , Area/Mandal/Land Mark : BHOWALI , State : UTARAKHAND , District : NAINITAL , City : Bhowali , Pincode : 263132 , Post Office : Bhowali",
  },
  {
    slNo: 170,
    Column2: "A2363",
    Column3: "DR. ABHILASHA TYAGI",
    Column4: "abhilashamadhutyagi@gmail.com",
    Column5: 9650677516,
    Column6: "HALDWANI",
    Column7: "UTTARAKHAND",
    Column8: "LM",
    Column9: "ACTIVE",
    Column10:
      "DoorNo/House No/Flat No/Name : 87, TRIVENI APPARTMENTS , Lane/Street/Village : H - 3 BLOCK, , Area/Mandal/Land Mark : VIKASPURI , State : DELHI NCR , District : DELHI , City : NEW DELHI , Pincode : 110018 , Post Office : ",
  },
  {
    slNo: 171,
    Column2: "A2364",
    Column3: "DR. ARADHANA ARYA",
    Column4: "ardhanaluckystar@gmail.com",
    Column5: 9997164899,
    Column6: "HALDWANI",
    Column7: "UTTARAKHAND",
    Column8: "LM",
    Column9: "ACTIVE",
    Column10:
      "DoorNo/House No/Flat No/Name : BITHORIYA NO 1, , Lane/Street/Village : VIKAS NAGAR , Area/Mandal/Land Mark : KUSUMKHERA , State : UTTARAKHAND , District : NAINITAL , City : HALDWANI , Pincode : 263139 , Post Office : ",
  },
  {
    slNo: 172,
    Column2: "A2365",
    Column3: "DR. ADITYA KUMAR CHAUHAN",
    Column4: "adidon27@gmail.com",
    Column5: 9410349997,
    Column6: "HALDWANI",
    Column7: "UTTARAKHAND",
    Column8: "LM",
    Column9: "ACTIVE",
    Column10:
      "DoorNo/House No/Flat No/Name : 274, AWAS VIKAS , Lane/Street/Village : BEHIND GURUDWARA , Area/Mandal/Land Mark : RUDRAPUR , State : UTTARAKHAND , District : UDHAM SINGH NAGAR , City : UDHAM SINGH NAGAR , Pincode : 263153 , Post Office : ",
  },
  {
    slNo: 173,
    Column2: "M2079",
    Column3: "DR. MEGHA ARORA",
    Column4: "meghs_arora@yahoo.com",
    Column5: 8193939959,
    Column6: "HALDWANI",
    Column7: "UTTARAKHAND",
    Column8: "LM",
    Column9: "ACTIVE",
    Column10:
      "DoorNo/House No/Flat No/Name : E-26, PHASE II, , Lane/Street/Village : KINGSTON ESTATAE, KASHIPUR ROAD, , Area/Mandal/Land Mark : RUDRAPUR , State : UTARAKHAND , District : RUDRAPUR , City : UDHAM SINGH NAGAR , Pincode : 263153 , Post Office : ",
  },
  {
    slNo: 174,
    Column2: "R1488",
    Column3: "DR. RAVINDRA SINGH SAMANT",
    Column4: "anaesthesia.samant@gmail.com",
    Column5: 9412421463,
    Column6: "HALDWANI",
    Column7: "UTTARAKHAND",
    Column8: "LM",
    Column9: "ACTIVE",
    Column10:
      "DoorNo/House No/Flat No/Name : VILL-JAIDAVPUR, PO HARIPUR NAYAK , Lane/Street/Village : R.T.O. OFFICE ROAD , Area/Mandal/Land Mark : KUSUMKHERA, HALDWANI , State : UTTARAKHAND , District : NAINITAL , City : NAINITAL , Pincode : 263139 , Post Office : ",
    Column11: "Retired",
  },
  {
    slNo: 175,
    Column2: "R2349",
    Column3: "DR. RAHUL SAXENA",
    Column4: "rahul.cool.1877@gmail.com",
    Column5: 9997456219,
    Column6: "HALDWANI",
    Column7: "UTTARAKHAND",
    Column8: "LM",
    Column9: "ACTIVE",
    Column10:
      "DoorNo/House No/Flat No/Name : BEHIND DAK BUNGLOW , Lane/Street/Village : CHANDMARI , Area/Mandal/Land Mark : KATHGOPAM , State : UTTARAKHAND , District : NAINITAL , City : HALDWANI , Pincode : 263126 , Post Office : ",
  },
  {
    slNo: 176,
    Column2: "M0892",
    Column3: "DR. MADAN MOHAN ARYA",
    Column4: "dr.madanmohanarya@gmail.com",
    Column5: 9760358395,
    Column6: "HALDWANI",
    Column7: "UTTARAKHAND",
    Column8: "LM",
    Column9: "ACTIVE",
    Column10:
      "DoorNo/House No/Flat No/Name : S/O LATE SRI.J.R.ARYA , Lane/Street/Village : H.NO.4-90, LINE.NO.2 , Area/Mandal/Land Mark : RAJENDRA NAGAR , State : UTTARAKHAND , District : NAINITAL , City : HALDWANI , Pincode : 263139 , Post Office : ",
  },
  {
    slNo: 177,
    Column2: "P1482",
    Column3: "URMILA PALARIA",
    Column4: "urmila_palaria@rediffmail.com",
    Column5: 9897088443,
    Column6: "HALDWANI",
    Column7: "UTTARAKHAND",
    Column8: "LM",
    Column9: "ACTIVE",
    Column10:
      "DoorNo/House No/Flat No/Name : W/O DR. D. C. PUNERA , Lane/Street/Village : TYPE IV, BLOCK- D/05, MEDICAL COLLEGE CAMPUS , Area/Mandal/Land Mark : RAMPUR ROAD, DIST. NAINITAL , State : , District : , City : HALDWANI , Pincode : 263139 , Post Office : ",
    Column11:
      "Address- Soban Singh Jeena Government Institute of Medical Sciences and Research  ",
  },
  {
    slNo: 178,
    Column2: "P1495",
    Column3: "K.D PANDEY",
    Column4: "dr.kdpande@gmail.com",
    Column5: 9837223296,
    Column6: "HALDWANI",
    Column7: "UTTARAKHAND",
    Column8: "LM",
    Column9: "ACTIVE",
    Column10:
      "DoorNo/House No/Flat No/Name : SRI KRISHNA VIHAR , Lane/Street/Village : MALLI- BAMORI , Area/Mandal/Land Mark : , State : UTTARAKHAND , District : NANITAL , City : HALDWANI , Pincode : 263139 , Post Office : ",
  },
  {
    slNo: 179,
    Column2: "P1506",
    Column3: "D.S PANCHPAL",
    Column4: "ds.panchpal017@gmail.com",
    Column5: 9412436440,
    Column6: "HALDWANI",
    Column7: "UTTARAKHAND",
    Column8: "LM",
    Column9: "ACTIVE",
    Column10:
      "DoorNo/House No/Flat No/Name : S S J BASE HOSPITAL , Lane/Street/Village : HALDWANI , Area/Mandal/Land Mark : , State : , District : , City : HALDWANI , Pincode : 263139 , Post Office : ",
  },
  {
    slNo: 180,
    Column2: "P1515",
    Column3: "DR. VIRENDRA KUMAR PUNERA",
    Column4: "virendrapunera@gmail.com",
    Column5: 9411322954,
    Column6: "HALDWANI",
    Column7: "UTTARAKHAND",
    Column8: "LM",
    Column9: "ACTIVE",
    Column10:
      "DoorNo/House No/Flat No/Name : C/O DR. JAGDISH CHANDRA PUNERA , Lane/Street/Village : CHANDRA WATI COLONY , Area/Mandal/Land Mark : CHOTE MUKHANI, PO-BARI MUKHANI, DIST. NAINITAL , State : UTTARAKHAND , District : NAINITAL , City : HALDWANI , Pincode : 263139 , Post Office : ",
  },
  {
    slNo: 181,
    Column2: "T0476",
    Column3: "DR. VIKAS KUMAR TYAGI",
    Column4: "drvikastyagi@gmail.com",
    Column5: 9258014248,
    Column6: "HALDWANI",
    Column7: "UTTARAKHAND",
    Column8: "LM",
    Column9: "ACTIVE",
    Column10:
      "DoorNo/House No/Flat No/Name : J- 3 , ARYAN APARTMENTS, , Lane/Street/Village : JADUGAR ROAD , OPPOSITE ST ANN'S SCHOOL , Area/Mandal/Land Mark : ROORKEE , State : , District : HARIDWAR , City : HARIDWAR , Pincode : 247667 , Post Office : CIVIL LINES ROORKEE",
  },
  {
    slNo: 182,
    Column2: "R2203",
    Column3: "RAJNI BHAKHUNI",
    Column4: "rajnibhakunibora@gmail.com",
    Column5: 8979624233,
    Column6: "HALDWANI",
    Column7: "UTTARAKHAND",
    Column8: "LM",
    Column9: "ACTIVE",
    Column10:
      "DoorNo/House No/Flat No/Name : NANDA VIHAR , Lane/Street/Village : R T O OFFICE ROAD , Area/Mandal/Land Mark : KUSUMKHEEA , NAINTAL , State : UTTARAKHAND , District : NAINITAL , City : HALDWANI , Pincode : 263139 , Post Office : ",
  },
  {
    slNo: 183,
    Column2: "S4172",
    Column3: "DR. SUBHRO MITRA",
    Column4: "subhromitra16@gmail.com",
    Column5: 9012600486,
    Column6: "HALDWANI",
    Column7: "UTTARAKHAND",
    Column8: "LM",
    Column9: "ACTIVE",
    Column10:
      "DoorNo/House No/Flat No/Name : FLAT NO P6,TYPE 4, PROFESSOR BLOCK , Lane/Street/Village : FLAT NO-P6, TYPE 4, PROFESSOR BLOCK, MEDICAL COLLEGE CAMPUS, GMC HALDWANI, BAREILLY ROAD , Area/Mandal/Land Mark : MEDICAL COLLEGE CAMPUS , State : UTTARAKHAND , District : NAINITAL , City : HALDWANI , Pincode : 263139 , Post Office : 263139",
  },
  {
    slNo: 184,
    Column2: "H0519",
    Column3: "DR. HINA JOSHI",
    Column4: "hinajoshi2584@gmail.com",
    Column5: 9873363386,
    Column6: "HALDWANI",
    Column7: "UTTARAKHAND",
    Column8: "LM",
    Column9: "ACTIVE",
    Column10:
      "DoorNo/House No/Flat No/Name : Durga vihar colony , Lane/Street/Village : Haldwani , Area/Mandal/Land Mark : kusumkhera , State : UTTARAKHAND , District : nanital , City : haldwani , Pincode : 263139 , Post Office : Kusumkhera",
  },
  {
    slNo: 185,
    Column2: "A2679",
    Column3: "DR. APARNA JAYARA",
    Column4: "aparna.jayara@gmail.com",
    Column5: 7895646823,
    Column6: "HALDWANI",
    Column7: "UTTARAKHAND",
    Column8: "LM",
    Column9: "ACTIVE",
    Column10:
      "DoorNo/House No/Flat No/Name : ROOM NO. 24 , Lane/Street/Village : SR HOSTEL , Area/Mandal/Land Mark : GMC HALDWANI , State : UTTARAKHAND , District : NAINITAL , City : HALDWANI , Pincode : 263139 , Post Office : HALDWANI",
    Column11: "Shifted to other state",
  },
  {
    slNo: 186,
    Column2: "S5138",
    Column3: "DR. SAGAR",
    Column4: "sagarpunetha@gmail.com",
    Column5: 9720454888,
    Column6: "HALDWANI",
    Column7: "UTTARAKHAND",
    Column8: "LM",
    Column9: "ACTIVE",
    Column10:
      "DoorNo/House No/Flat No/Name : Brij Lal Hospital and Research Centre , Lane/Street/Village : Nainital Road , Area/Mandal/Land Mark : Anandi Tower , State : UTARAKHAND , District : Nainital , City : Haldwani , Pincode : 263139 , Post Office : ",
  },
  {
    slNo: 187,
    Column2: "S5468",
    Column3: "DR. MEGHA",
    Column4: "docmeghagupta@gmail.com",
    Column5: 9997034668,
    Column6: "HALDWANI",
    Column7: "UTTARAKHAND",
    Column8: "LM",
    Column9: "ACTIVE",
    Column10:
      "DoorNo/House No/Flat No/Name : NEAR BLOCK OFFICE , Lane/Street/Village : SITARGANJ ROAD , Area/Mandal/Land Mark : KHATIMA , State : UTTARAKHAND , District : UDHAM SINGH NAGAR , City : KHATIMA , Pincode : 262308 , Post Office : ",
  },
  {
    slNo: 188,
    Column2: "C0850",
    Column3: "DR. CHARUL JAKHWAL",
    Column4: "doc.charuljakhwal@gmail.com",
    Column5: 9690103968,
    Column6: "HALDWANI",
    Column7: "UTTARAKHAND",
    Column8: "LM",
    Column9: "ACTIVE",
    Column10:
      "DoorNo/House No/Flat No/Name : ROOM 11 , Lane/Street/Village : SENIOR RESIDENT HOSTEL, GMC HALDWANI CAMPUS, , Area/Mandal/Land Mark : HALDWANI , State : UTTARAKHAND , District : NAINITAL , City : HALDWANI , Pincode : 263139 , Post Office : ",
    Column11: "Mob no. 8077981377",
  },
  {
    slNo: 189,
    Column2: "M2573",
    Column3: "DR. MANNI PUNYANI",
    Column4: "punyani.manni@gmail.com",
    Column5: 9568217063,
    Column6: "HALDWANI",
    Column7: "UTTARAKHAND",
    Column8: "LM",
    Column9: "ACTIVE",
    Column10:
      "DoorNo/House No/Flat No/Name : House number 667 , Lane/Street/Village : Rudrapur , Area/Mandal/Land Mark : Punyani niwas near Sharda enclave bhura Rani road , State : UTTARAKHAND , District : UDHAM SINGH NAGAR , City : Rudrapur , Pincode : 263153 , Post Office : Rudrapur",
  },
  {
    slNo: 190,
    Column2: "H0549",
    Column3: "DR. HEM CHANDRA BHATT",
    Column4: "hembhatt2@gmail.com",
    Column5: 7895332209,
    Column6: "HALDWANI",
    Column7: "UTTARAKHAND",
    Column8: "LM",
    Column9: "ACTIVE",
    Column10:
      "DoorNo/House No/Flat No/Name : BOYS HOSTEL ROOM NO.36 (F BLOCK) , Lane/Street/Village : GMC AMRITSAR , Area/Mandal/Land Mark : PUNJAB , State : PUNJAB , District : AMRITSAR , City : AMRITSAR , Pincode : 143001 , Post Office : ",
    Column11: "Mob no. 7505259025",
  },
  {
    slNo: 191,
    Column2: "K2062",
    Column3: "DR. KAUSHAL KUMAR",
    Column4: "drkaushal04@gmail.com",
    Column5: 7409632135,
    Column6: "HALDWANI",
    Column7: "UTTARAKHAND",
    Column8: "LM",
    Column9: "ACTIVE",
    Column10:
      "DoorNo/House No/Flat No/Name : GURU TEGH BAHADUR MULTI-SPECIALITY HOSPITAL SITARGANJ , Lane/Street/Village : DISTT- UDHAM SINGH NAGAR , Area/Mandal/Land Mark : UDHAM SINGH NAGAR , State : UTTARAKHAND , District : UDHAM SINGH NAGAR , City : UDHAM SINGH NAGAR , Pincode : 262405 , Post Office : HALDWANI",
  },
  {
    slNo: 192,
    Column2: "A3021",
    Column3: "DR. ABHISHEK NAUTIYAL",
    Column4: "abhisheknautiyal23@gmail.com",
    Column5: 8791204969,
    Column6: "HALDWANI",
    Column7: "UTTARAKHAND",
    Column8: "LM",
    Column9: "ACTIVE",
    Column10:
      "DoorNo/House No/Flat No/Name : HOUSE NO 368, lane 6A , Lane/Street/Village : SHANTI VIHAR PHASE 2, AJABPUR , Area/Mandal/Land Mark : NEAR HOTEL SOLITAIRE , State : UTTARAKHAND , District : DEHRADUN , City : DEHRADUN , Pincode : 248121 , Post Office : DEHRADUN",
  },
  {
    slNo: 193,
    Column2: "N1410",
    Column3: "DR. NIKITA MAMGAIN",
    Column4: "nmnikz23@gmail.com",
    Column5: 9639625586,
    Column6: "HALDWANI",
    Column7: "UTTARAKHAND",
    Column8: "LM",
    Column9: "ACTIVE",
    Column10:
      "DoorNo/House No/Flat No/Name : HOUSE NO 368 , Lane/Street/Village : LANE 6A , Area/Mandal/Land Mark : SHANTIVIHAR PHASE 2, AJABPUR KALAN , State : UTTARAKHAND , District : DEHRADUN , City : DEHRADUN , Pincode : 248121 , Post Office : AJABPUR",
  },
  {
    slNo: 194,
    Column2: "P2556",
    Column3: "DR. PRIYANKA CHOURASIA",
    Column4: "chaurasiyapriyanka11@gmail.com",
    Column5: 8384864014,
    Column6: "HALDWANI",
    Column7: "UTTARAKHAND",
    Column8: "LM",
    Column9: "ACTIVE",
    Column10:
      "DoorNo/House No/Flat No/Name : ROOM NO 60, SR HOSTAL,GOVERNMENT MEDICAL COLLEGE , Lane/Street/Village : RAMRPUR ROAD , Area/Mandal/Land Mark : , State : UTTARAKHAND , District : NAINITAL , City : HALDWANI , Pincode : 263139 , Post Office : HALDWANI",
    Column11: "Mob no. 8517977751",
  },
  {
    slNo: 195,
    Column2: "S5712",
    Column3: "DR. SHIKHA SINGH",
    Column4: "drshikhasingh2305@gmail.com",
    Column5: 9953619539,
    Column6: "HALDWANI",
    Column7: "UTTARAKHAND",
    Column8: "LM",
    Column9: "ACTIVE",
    Column10:
      "DoorNo/House No/Flat No/Name : ROOM NO. 91, SECOND FLOOR , Lane/Street/Village : GOVERNMENT MEDICAL COLLEGE HALDWANI , Area/Mandal/Land Mark : , State : UTTARAKHAND , District : NAINITAL , City : HALDWANI , Pincode : 263139 , Post Office : ",
  },
  {
    slNo: 196,
    Column2: "S5866",
    Column3: "DR. SANIA PARVEEN",
    Column4: "sania.jc.9@gmail.com",
    Column5: 8791204859,
    Column6: "HALDWANI",
    Column7: "UTTARAKHAND",
    Column8: "LM",
    Column9: "ACTIVE",
    Column10:
      "DoorNo/House No/Flat No/Name : 102 , Lane/Street/Village : ALIG COURT , Area/Mandal/Land Mark : SIR SYED NAGAR , State : UTTAR PRADESH , District : ALIGARH , City : ALIGARH , Pincode : 202002 , Post Office : ",
    Column11: "Shifted to other state",
  },
  {
    slNo: 197,
    Column2: "P2647",
    Column3: "DR. PAWAN KUMAR DWIVEDI",
    Column4: "pawan4346@gmail.com",
    Column5: 9410651817,
    Column6: "HALDWANI",
    Column7: "UTTARAKHAND",
    Column8: "LM",
    Column9: "ACTIVE",
    Column10:
      "DoorNo/House No/Flat No/Name : SIJWALITOAK TALLI , Lane/Street/Village : BAMORI , Area/Mandal/Land Mark : BAMORI , State : UTTARAKHAND , District : NAINITAL , City : HALDWANI , Pincode : 263139 , Post Office : HALDWANI",
  },
  {
    slNo: 199,
    Column2: "P2654",
    Column3: "DR. PRATAP SINGH SHAHI",
    Column4: "mailtopratap@rediff.com",
    Column5: 8800824278,
    Column6: "HALDWANI",
    Column7: "UTTARAKHAND",
    Column8: "LM",
    Column9: "ACTIVE",
    Column10:
      "DoorNo/House No/Flat No/Name : A 601 , Lane/Street/Village : MAHAJAN SHREE CGHS LTD , Area/Mandal/Land Mark : PLOT 13, SECTOR 23, DWARKA , State : DELHI NCR , District : CENTRAL DELHI , City : NEW DELHI , Pincode : 110077 , Post Office : NEWDELHI",
  },
  {
    slNo: 200,
    Column2: "K2151",
    Column3: "DR. KAUSHAL PANDEY",
    Column4: "royaldrkaushal@gmail.com",
    Column5: 9411537962,
    Column6: "HALDWANI",
    Column7: "UTTARAKHAND",
    Column8: "LM",
    Column9: "ACTIVE",
    Column10:
      "DoorNo/House No/Flat No/Name : P.PANDEY & SONS BHAWAN , Lane/Street/Village : VIKASPURI GALI NO. 1, NAWABI ROAD , Area/Mandal/Land Mark : MALLA GORAKHPUR , State : UTTARAKHAND , District : NAINITAL , City : HALDWANI , Pincode : 263139 , Post Office : HALDWANI",
  },
  {
    slNo: 201,
    Column2: "K2160",
    Column3: "DR. KAMAL NAYAN JOSHI",
    Column4: "kamalnj1114@gmail.com",
    Column5: 9639383113,
    Column6: "HALDWANI",
    Column7: "UTTARAKHAND",
    Column8: "LM",
    Column9: "ACTIVE",
    Column10:
      "DoorNo/House No/Flat No/Name : ROOM NO. 14 , Lane/Street/Village : OPERATION THEATRE , Area/Mandal/Land Mark : DISTRICT FEMALE HOSPITAL , State : UTTARAKHAND , District : PITHORAGARH , City : PITHORAGARH , Pincode : 262501 , Post Office : HO PITHORAGARH",
  },
  {
    slNo: 202,
    Column2: "R2821",
    Column3: "DR. RISHIKA RATHORE",
    Column4: "dr.rishikarathore@gmail.com",
    Column5: 9997404039,
    Column6: "HALDWANI",
    Column7: "UTTARAKHAND",
    Column8: "LM",
    Column9: "ACTIVE",
    Column10:
      "DoorNo/House No/Flat No/Name : ROOM NO 54 , Lane/Street/Village : SR HOSTEL , Area/Mandal/Land Mark : GMC CAMPUS , State : UTTARAKHAND , District : NAINITAL , City : HALDWANI , Pincode : 263139 , Post Office : ",
  },
  {
    slNo: 203,
    Column2: "K2162",
    Column3: "DR. KAVITA POKHARIA",
    Column4: "kavitapokharia210@gmail.com",
    Column5: 8938813376,
    Column6: "HALDWANI",
    Column7: "UTTARAKHAND",
    Column8: "LM",
    Column9: "ACTIVE",
    Column10:
      "DoorNo/House No/Flat No/Name : JAI DURGA COLONY , Lane/Street/Village : VILL- DEWAL CHAUR , Area/Mandal/Land Mark : P.O.MANPUR WEST , State : UTTARAKHAND , District : NAINITAL , City : HALDWANI , Pincode : 263139 , Post Office : MANPUR WEST",
  },
  {
    slNo: 204,
    Column2: "P2984",
    Column3: "DR. PRANAV PUJARI",
    Column4: "jpranav65.pp@gmail.com",
    Column5: 9610304310,
    Column6: "HALDWANI",
    Column7: "UTTARAKHAND",
    Column8: "LM",
    Column9: "ACTIVE",
    Column10:
      "DoorNo/House No/Flat No/Name : INTERN BOYS HOSTEL , Lane/Street/Village : JLN MEDICAL COLLEGE , Area/Mandal/Land Mark : RAJASTHAN , State : RAJASTHAN , District : AJMER , City : AJMER , Pincode : 305001 , Post Office : ",
  },
  {
    slNo: 205,
    Column2: "D1864",
    Column3: "DR. DEEP CHILANA",
    Column4: "chilanadeep@gmail.com",
    Column5: 8392875547,
    Column6: "HALDWANI",
    Column7: "UTTARAKHAND",
    Column8: "LM",
    Column9: "ACTIVE",
    Column10:
      "DoorNo/House No/Flat No/Name : H.NO F-94 , Lane/Street/Village : ALLIANCE COLONY , Area/Mandal/Land Mark : KASHIPUR ROAD , State : UTTARAKHAND , District : UDHAM SINGH NAGAR , City : RUDRAPUR , Pincode : 263153 , Post Office : ",
  },
  {
    slNo: 206,
    Column2: "M3145",
    Column3: "DR. MANISHA JALAL",
    Column4: "manisha26jalal@gmail.com",
    Column5: 9711981506,
    Column6: "HALDWANI",
    Column7: "UTTARAKHAND",
    Column8: "LM",
    Column9: "ACTIVE",
    Column10:
      "DoorNo/House No/Flat No/Name : H.NO A/5, , Lane/Street/Village : SHIVPURAM PHASE 1, KUSUMKHERA , Area/Mandal/Land Mark : NEAR HANUMAN MANDIR, HALDWANI , State : UTTARAKHAND , District : NAINITAL , City : HALDWANI , Pincode : 263139 , Post Office : HALDWANI",
  },
  {
    slNo: 207,
    Column2: "N2016",
    Column3: "DR. NEELAM SHARMA",
    Column4: "neelighata@gmail.com",
    Column5: 9456696179,
    Column6: "HALDWANI",
    Column7: "UTTARAKHAND",
    Column8: "LM",
    Column9: "ACTIVE",
    Column10:
      "DoorNo/House No/Flat No/Name : NILIAM COLONY, HALDWANI , Lane/Street/Village : H.NO. 8 SIDDHESWAR BIHAR PHASE 1 ,BR2 ROAD, NILIAM COLONY, HALDWANI , Area/Mandal/Land Mark : UTTARAKHAND , State : UTTARAKHAND , District : NAINITAL , City : HALDWANI , Pincode : 263139 , Post Office : ",
  },
  {
    slNo: 208,
    Column2: "S7182",
    Column3: "DR. SHAILESH KUMAR LOHANI",
    Column4: "drshaileshlohani@gmail.com",
    Column5: 7088973697,
    Column6: "HALDWANI",
    Column7: "UTTARAKHAND",
    Column8: "LM",
    Column9: "ACTIVE",
    Column10:
      "DoorNo/House No/Flat No/Name : SAINIK COLONY , Lane/Street/Village : HARIPUR NAYAK, NEAR ORION STAR SCHOOL, RTO ROAD , Area/Mandal/Land Mark : HALDWANI , State : UTTARAKHAND , District : NAINITAL , City : HALDWANI , Pincode : 263139 , Post Office : HALDWANI",
  },
  {
    slNo: 209,
    Column2: "A2362",
    Column3: "DR. ADITYA KUMAR",
    Column4: "optimism1987@gmail.com",
    Column5: 7895180604,
    Column6: "HALDWANI",
    Column7: "UTTARAKHAND",
    Column8: "ALM",
    Column9: "ACTIVE",
    Column10:
      "DoorNo/House No/Flat No/Name : 30 / 3 MITRALOK COLONY , Lane/Street/Village : BALLUPUR ROAD, , Area/Mandal/Land Mark : , State : , District : , City : DEHRADUN , Pincode : 248001 , Post Office : ",
    Column11: "Shifted to other state",
  },
  {
    slNo: 210,
    Column2: "S4849",
    Column3: "DR. SHAGUFTA ANSARI",
    Column4: "shagufta.sanyo@gmail.com",
    Column5: 9897565607,
    Column6: "HALDWANI",
    Column7: "UTTARAKHAND",
    Column8: "ALM",
    Column9: "ACTIVE",
    Column10:
      "DoorNo/House No/Flat No/Name : A4 / 94, STAFF COLONY , Lane/Street/Village : CENTURY PULP AND PAPER , Area/Mandal/Land Mark : LALKUA , State : , District : , City : NAINITAL , Pincode : 262402 , Post Office : ",
  },
  {
    slNo: 211,
    Column2: "M2574/A",
    Column3: "DR. MAHAK MEHTA",
    Column4: "mahak.mehta035@gmail.com",
    Column5: 9953791185,
    Column6: "HALDWANI",
    Column7: "UTTARAKHAND",
    Column8: "ALM",
    Column9: "ACTIVE",
    Column10:
      "DoorNo/House No/Flat No/Name : ROOM NO 1 SR PG HOSTEL , Lane/Street/Village : GOVERNMENT MEDICAL COLLEGE HALDWANI , Area/Mandal/Land Mark : HALDWANI , State : UTTARAKHAND , District : NAINITAL , City : HALDWANI , Pincode : 263139 , Post Office : ",
    Column11: "Shifted to other state",
  },
  {
    slNo: 212,
    Column2: "S5660/A",
    Column3: "DR. SWATI ARYA",
    Column4: "aryaswati46@gmail.com",
    Column5: 8958528109,
    Column6: "HALDWANI",
    Column7: "UTTARAKHAND",
    Column8: "ALM",
    Column9: "ACTIVE",
    Column10:
      "DoorNo/House No/Flat No/Name : SR HOSTEL ROOM NO 57 , Lane/Street/Village : GMC HALDWANI , Area/Mandal/Land Mark : GMC HALDWANI CAMPUS , State : UTTARAKHAND , District : NAINITAL , City : HALDWANI , Pincode : 263139 , Post Office : HALDWANI",
    Column11: "Shifted to other state",
  },
  {
    slNo: 213,
    Column2: "A3022/A",
    Column3: "DR. DR ABHISHEK MADESHIA",
    Column4: "abhishekmadeshia1509@gmail.com",
    Column5: 9473901593,
    Column6: "HALDWANI",
    Column7: "UTTARAKHAND",
    Column8: "ALM",
    Column9: "ACTIVE",
    Column10:
      "DoorNo/House No/Flat No/Name : ROOM NO.12 , Lane/Street/Village : SR HOSTEL GOVERMENT MEDICAL COLLEGE , Area/Mandal/Land Mark : NEAR POLICE CHOWKI , State : UTTARAKHAND , District : NAINITAL , City : HALDWANI , Pincode : 263139 , Post Office : HALDWANI",
    Column11: "Shifted to other state",
  },
  {
    slNo: 214,
    Column2: "H0570/A",
    Column3: "DR. HARSHITA LAL",
    Column4: "harshita.833@gmail.com",
    Column5: 9837512201,
    Column6: "HALDWANI",
    Column7: "UTTARAKHAND",
    Column8: "ALM",
    Column9: "ACTIVE",
    Column10:
      "DoorNo/House No/Flat No/Name : VILL PURANPURNAINWAL , Lane/Street/Village : KAMALUAGANJA , Area/Mandal/Land Mark : P.O LAMACHAUR , State : UTTARAKHAND , District : NAINITAL , City : HALDWANI , Pincode : 263139 , Post Office : LAMACHAUR",
  },
  {
    slNo: 215,
    Column2: "P2559/A",
    Column3: "DR. PALLAVI CHAUHAN",
    Column4: "drpallavichauhan12@gmail.com",
    Column5: 7409274631,
    Column6: "HALDWANI",
    Column7: "UTTARAKHAND",
    Column8: "ALM",
    Column9: "ACTIVE",
    Column10:
      "DoorNo/House No/Flat No/Name : ROOM NO 21 SENIOR RESIDENT HOSTEL , Lane/Street/Village : GOVERMENT MEDICAL COLLEGE HALDWANI , Area/Mandal/Land Mark : , State : UTTARAKHAND , District : NAINITAL , City : HALDWANI , Pincode : 263139 , Post Office : HALDWANI",
  },
  {
    slNo: 216,
    Column2: "K2106",
    Column3: "DR. KEWALA NAND JOSHI",
    Column4: "drknjoshi@gmail.com",
    Column5: 9450597334,
    Column6: "HALDWANI",
    Column7: "UTTARAKHAND",
    Column8: "ALM",
    Column9: "ACTIVE",
    Column10:
      "DoorNo/House No/Flat No/Name : C-24 , Lane/Street/Village : LAXMI VISHNU VIHAR , Area/Mandal/Land Mark : BARI MUKHANI , State : UTTARAKHAND , District : NAINITAL , City : HALDWANI , Pincode : 263139 , Post Office : BARI MUKHANI",
  },
  {
    slNo: 217,
    Column2: "S5993/A",
    Column3: "DR. SHIVANI RAMOLA",
    Column4: "shivaniramola@gmail.com",
    Column5: 7895598799,
    Column6: "HALDWANI",
    Column7: "UTTARAKHAND",
    Column8: "ALM",
    Column9: "ACTIVE",
    Column10:
      "DoorNo/House No/Flat No/Name : ROOM NO G26 , Lane/Street/Village : INTERNS GIRLS HOSTEL , Area/Mandal/Land Mark : GOVT MEDICAL COLLEGE , State : UTTARAKHAND , District : NAINITAL , City : HALDWANI , Pincode : 263139 , Post Office : ",
  },
  {
    slNo: 218,
    Column2: "A3294/A",
    Column3: "DR. ANKITA RAWAT",
    Column4: "anki.raw17@gmail.com",
    Column5: 9410702837,
    Column6: "HALDWANI",
    Column7: "UTTARAKHAND",
    Column8: "ALM",
    Column9: "ACTIVE",
    Column10:
      "DoorNo/House No/Flat No/Name : ROOM NO G8 , Lane/Street/Village : INTERN GIRLS HOSTEL , Area/Mandal/Land Mark : STH & MEDICAL COLLEGE HALDWANI , State : UTTARAKHAND , District : NAINITAL , City : HALDWANI , Pincode : 263139 , Post Office : HALDWANI",
  },
  {
    slNo: 219,
    Column2: "M2799/A",
    Column3: "DR. MONICA BHANDARI",
    Column4: "doctormonics@gmail.com",
    Column5: 9837945321,
    Column6: "HALDWANI",
    Column7: "UTTARAKHAND",
    Column8: "ALM",
    Column9: "ACTIVE",
    Column10:
      "DoorNo/House No/Flat No/Name : ROOM NO 54 , Lane/Street/Village : SR HOSTEL , Area/Mandal/Land Mark : GMC HALDWANI , State : UTTARAKHAND , District : NAINITAL , City : HALDWANI , Pincode : 263129 , Post Office : ",
    Column11: "email id-monikabhandari129@gmail.com ; mob no.-9675458043",
  },
  {
    slNo: 220,
    Column2: "S6040/A",
    Column3: "DR. SATYA PRAKASH MISHRA",
    Column4: "s.p.narcissist@gmail.com",
    Column5: 9897587989,
    Column6: "HALDWANI",
    Column7: "UTTARAKHAND",
    Column8: "ALM",
    Column9: "ACTIVE",
    Column10:
      "DoorNo/House No/Flat No/Name : ROOM NO. 106 , Lane/Street/Village : INTERN BOYS HOSTEL , Area/Mandal/Land Mark : GOVT. MEDICAL COLLEGE, HALDWANI , State : UTTARAKHAND , District : NAINITAL , City : HALDWANI , Pincode : 263140 , Post Office : HALDWANI",
    Column11: "Shifted to other state",
  },
  {
    slNo: 221,
    Column2: "A3316/A",
    Column3: "DR. PRAMITA ",
    Column4: "dr.pramitatamta92@gmail.com",
    Column5: 9760269161,
    Column6: "HALDWANI",
    Column7: "UTTARAKHAND",
    Column8: "ALM",
    Column9: "ACTIVE",
    Column10:
      "DoorNo/House No/Flat No/Name : ROOM NO-74 , Lane/Street/Village : S R HOSTEL , Area/Mandal/Land Mark : GOVT MEDICAL COLLEGE , State : UTTARAKHAND , District : NAINITAL , City : HALDWANI , Pincode : 263139 , Post Office : HALDWANI",
  },
  {
    slNo: 222,
    Column2: "A3626/A",
    Column3: "DR. ABHA TIWARI",
    Column4: "abha25tiwari@gmail.com",
    Column5: 9634245976,
    Column6: "HALDWANI",
    Column7: "UTTARAKHAND",
    Column8: "ALM",
    Column9: "ACTIVE",
    Column10:
      "DoorNo/House No/Flat No/Name : GAYATRI NAGAR , Lane/Street/Village : DAMUWADHUNGA , Area/Mandal/Land Mark : KATHGODAM , State : UTTARAKHAND , District : NAINITAL , City : HALDWANI , Pincode : 263126 , Post Office : KATHGODAM",
  },
  {
    slNo: 223,
    Column2: "V1754/A",
    Column3: "DR. RITU",
    Column4: "rituvivaanrathi@gmail.com",
    Column5: 7500174440,
    Column6: "HALDWANI",
    Column7: "UTTARAKHAND",
    Column8: "ALM",
    Column9: "ACTIVE",
    Column10:
      "DoorNo/House No/Flat No/Name : D/O LATE MR MADHO RAM 55 , Lane/Street/Village : VILLAGE HIRAGARH DALIP SINGH , Area/Mandal/Land Mark : , State : UTTARAKHAND , District : NAINITAL , City : HALDWANI , Pincode : 263139 , Post Office : KATHGODAM",
  },
  {
    slNo: 224,
    Column2: "A3661/A",
    Column3: "DR. ANJANA",
    Column4: "anjanarawatdr@gmail.com",
    Column5: 9410951488,
    Column6: "HALDWANI",
    Column7: "UTTARAKHAND",
    Column8: "ALM",
    Column9: "ACTIVE",
    Column10:
      "DoorNo/House No/Flat No/Name : G11 NEW JR HOSTEL , Lane/Street/Village : GMC HALDWANI , Area/Mandal/Land Mark : UTTARAKHAND , State : UTTARAKHAND , District : NAINITAL , City : HALDWANI , Pincode : 263139 , Post Office : ",
  },
  {
    slNo: 225,
    Column2: "M2956/A",
    Column3: "DR. MONIKA KHATI",
    Column4: "monika.manie@gmail.com",
    Column5: 9627167965,
    Column6: "HALDWANI",
    Column7: "UTTARAKHAND",
    Column8: "ALM",
    Column9: "ACTIVE",
    Column10:
      "DoorNo/House No/Flat No/Name : ROOM NO G-2, PG GIRLS HOSTEL , Lane/Street/Village : GMC HALDWANI , Area/Mandal/Land Mark : NEAR MBBS GIRLS HOSTEL , State : UTTARAKHAND , District : NAINITAL , City : HALDWANI , Pincode : 263139 , Post Office : ",
  },
  {
    slNo: 226,
    Column2: "N1629/A",
    Column3: "DR. NIRDESH KUMAR",
    Column4: "nirdeshkumardabre@gmail.com",
    Column5: 8006955595,
    Column6: "HALDWANI",
    Column7: "UTTARAKHAND",
    Column8: "ALM",
    Column9: "ACTIVE",
    Column10:
      "DoorNo/House No/Flat No/Name : ROOM NO 315 , Lane/Street/Village : INTERN BOYS HOSTEL , Area/Mandal/Land Mark : GOVT. MEDICAL COLLEGE, HALDWANI , State : UTTARAKHAND , District : NAINITAL , City : HALDWANI , Pincode : 263139 , Post Office : HALDWANI",
  },
  {
    slNo: 227,
    Column2: "S0896/A",
    Column3: "DR. SUDESH PRASAD",
    Column4: "semwal.prasad@gmail.com",
    Column5: 9634965903,
    Column6: "HALDWANI",
    Column7: "UTTARAKHAND",
    Column8: "ALM",
    Column9: "ACTIVE",
    Column10:
      "DoorNo/House No/Flat No/Name : ROOM NO 325 , Lane/Street/Village : INTERN BOYS HOSTEL , Area/Mandal/Land Mark : GOVT MEDICAL COLLEGE HALDWANI , State : UTTARAKHAND , District : NAINITAL , City : HALDWANI , Pincode : 263139 , Post Office : ",
  },
  {
    slNo: 228,
    Column2: "N1631/A",
    Column3: "DR. YEETI UPRETI",
    Column4: "yeeti.upreti@gmail.com",
    Column5: 7456091188,
    Column6: "HALDWANI",
    Column7: "UTTARAKHAND",
    Column8: "ALM",
    Column9: "ACTIVE",
    Column10:
      "DoorNo/House No/Flat No/Name : DIAMOND HOSTEL ROOM NO 22 FIRST FLOOR , Lane/Street/Village : MEDICAL COLLEGE HALDWANI , Area/Mandal/Land Mark : MEDICAL COLLEGE HALDWANI , State : UTTARAKHAND , District : NAINITAL , City : HALDWANI , Pincode : 263139 , Post Office : HALDWANI",
  },
  {
    slNo: 229,
    Column2: "G1546/A",
    Column3: "DR. GAURAV JOSHI",
    Column4: "jgauravjoshi2721987@gmail.com",
    Column5: 9193103159,
    Column6: "HALDWANI",
    Column7: "UTTARAKHAND",
    Column8: "ALM",
    Column9: "ACTIVE",
    Column10:
      "DoorNo/House No/Flat No/Name : ROOM NO.50, INTERNS HOSTEL , Lane/Street/Village : RAMPUR ROAD , Area/Mandal/Land Mark : GMC, HALDWANI , State : UTTARAKHAND , District : NAINITAL , City : HALDWANI , Pincode : 263139 , Post Office : HALDWANI",
  },
  {
    slNo: 230,
    Column2: "M3012/A",
    Column3: "DR. MOHAMMAD AOURANGZAIB",
    Column4: "aquib02siddiqui@gmail.com",
    Column5: 7536887610,
    Column6: "HALDWANI",
    Column7: "UTTARAKHAND",
    Column8: "ALM",
    Column9: "ACTIVE",
    Column10:
      "DoorNo/House No/Flat No/Name : HAIDER TIMBER TRADERS , Lane/Street/Village : LAKRI MANDI , Area/Mandal/Land Mark : JASPUR , State : UTTARAKHAND , District : UDHAM SINGH NAGAR , City : JASPUR , Pincode : 244712 , Post Office : JASPUR",
  },
  {
    slNo: 231,
    Column2: "D1884/A",
    Column3: "DR. DEEPTI MEHTA",
    Column4: "dr.deeptimehra21@gmail.com",
    Column5: 7906985801,
    Column6: "HALDWANI",
    Column7: "UTTARAKHAND",
    Column8: "ALM",
    Column9: "ACTIVE",
    Column10:
      "DoorNo/House No/Flat No/Name : DR. DEEPTI MEHTA , Lane/Street/Village : MEHTAN STREET MEHTA KUTEER , Area/Mandal/Land Mark : KATHERA BAZAR , State : UTTARAKHAND , District : HARIDWAR , City : JWALAPUR , Pincode : 249407 , Post Office : JWALAPUR",
  },
  {
    slNo: 232,
    Column2: "N1782/A",
    Column3: "DR. NAVEEN CHANDRA SINGH",
    Column4: "naveen.bisht28@gmail.com",
    Column5: 9627883453,
    Column6: "HALDWANI",
    Column7: "UTTARAKHAND",
    Column8: "ALM",
    Column9: "ACTIVE",
    Column10:
      "DoorNo/House No/Flat No/Name : DR NAVEEN BISHT NIWAS , Lane/Street/Village : VILLAGE DIGOTI , Area/Mandal/Land Mark : POST OFFICE MAJKHALI , State : UTTARAKHAND , District : ALMORA , City : RANIKHET , Pincode : 263652 , Post Office : MAJKHALI",
  },
  {
    slNo: 233,
    Column2: "G1688/A",
    Column3: "DR. GARGI BISHT",
    Column4: "gargi.vsbisht@gmail.com",
    Column5: 9604842852,
    Column6: "HALDWANI",
    Column7: "UTTARAKHAND",
    Column8: "ALM",
    Column9: "ACTIVE",
    Column10:
      "DoorNo/House No/Flat No/Name : TRILOKPRIYA AMRAWATI VIHAR , Lane/Street/Village : TALLI BAMORI HALDWANI , Area/Mandal/Land Mark : NAINITAL , State : UTTARAKHAND , District : NAINITAL , City : HALDWANI , Pincode : 263139 , Post Office : ",
  },
  {
    slNo: 234,
    Column2: "R3403/A",
    Column3: "DR. RENA GURUNG",
    Column4: "renagurung15@gmail.com",
    Column5: 9897544392,
    Column6: "HALDWANI",
    Column7: "UTTARAKHAND",
    Column8: "ALM",
    Column9: "ACTIVE",
    Column10:
      "DoorNo/House No/Flat No/Name : GIRLS PG HOSTEL S24 , Lane/Street/Village : GOVERMENT MEDICAL COLLEGE,HALDWANI , Area/Mandal/Land Mark : , State : UTTARAKHAND , District : NAINITAL , City : HALDWANI , Pincode : 263139 , Post Office : HALDWANI",
  },
  {
    slNo: 235,
    Column2: "A4118/A",
    Column3: "DR. ARCHANA MEHRA",
    Column4: "archana.mehra.255@gmail.com",
    Column5: 8477043662,
    Column6: "HALDWANI",
    Column7: "UTTARAKHAND",
    Column8: "ALM",
    Column9: "ACTIVE",
    Column10:
      "DoorNo/House No/Flat No/Name : ROOM NO S2 PG GIRLS HOSTEL , Lane/Street/Village : NEAR RAMPUR ROAD , Area/Mandal/Land Mark : GMC HALDWANI , State : UTTARAKHAND , District : NAINITAL , City : HALDWANI , Pincode : 263139 , Post Office : HALDWANI",
  },
  {
    slNo: 236,
    Column2: "M3325/A",
    Column3: "DR. MANOJ SINGH KUNWAR",
    Column4: "mskunwar5375@gmail.com",
    Column5: 7456914657,
    Column6: "HALDWANI",
    Column7: "UTTARAKHAND",
    Column8: "ALM",
    Column9: "ACTIVE",
    Column10:
      "DoorNo/House No/Flat No/Name : C/O MR P S POONA, MADHUWAN COLONY , Lane/Street/Village : LOHRIYASAAL TALLA, UNCHAPUL , Area/Mandal/Land Mark : NEXT TO MAPLEWOOD SCHOOL , State : UTTARAKHAND , District : NAINITAL , City : HALDWANI , Pincode : 263139 , Post Office : KATHGHARIYA",
  },
  {
    slNo: 237,
    Column2: "A4408/A",
    Column3: "DR. GAURAV SHARMA",
    Column4: "maildrgauravsharma@gmail.com",
    Column5: 8449855918,
    Column6: "HALDWANI",
    Column7: "UTTARAKHAND",
    Column8: "ALM",
    Column9: "ACTIVE",
    Column10:
      "DoorNo/House No/Flat No/Name : WARD NO 19 , Lane/Street/Village : MALLA GORAKHPUR , Area/Mandal/Land Mark : HALDWANI , State : UTTARAKHAND , District : NAINITAL , City : HALDWANI , Pincode : 263139 , Post Office : HALDWANI",
  },
  {
    slNo: 238,
    Column2: "A4440/A",
    Column3: "DR. SAMPURNANAND",
    Column4: "sampunk34@gmail.com",
    Column5: 8979447443,
    Column6: "HALDWANI",
    Column7: "UTTARAKHAND",
    Column8: "ALM",
    Column9: "ACTIVE",
    Column10:
      "DoorNo/House No/Flat No/Name : ROOM NO S 10 , NEW PG BOYS HOSTEL , Lane/Street/Village : GMC HALDWANI , Area/Mandal/Land Mark : GMC HALDWANI , State : UTTARAKHAND , District : NAINITAL , City : HALDWANI , Pincode : 263139 , Post Office : ",
  },
  {
    slNo: 239,
    Column2: "A4819/A",
    Column3: "DR. ASHISH KUMAR SINGH",
    Column4: "ashish.singh585@gmail.com",
    Column5: 9997898148,
    Column6: "HALDWANI",
    Column7: "UTTARAKHAND",
    Column8: "ALM",
    Column9: "ACTIVE",
    Column10:
      "DoorNo/House No/Flat No/Name : CHANDRIKA'S VILLA , Lane/Street/Village : S/O DR.A.K.SINGH SHREE KRISHNA COLONY FATTABANGAR GAURA PADAW NEAR ADARSH NURSERY HALDWANI 263139 , Area/Mandal/Land Mark : DIBER ARJUNPUR , State : UTTARAKHAND , District : NAINITAL , City : HALDWANI , Pincode : 263139 , Post Office : HALDWANI",
  },
  {
    slNo: 240,
    Column2: "M3517/A",
    Column3: "DR. MOHAMMAD AQIL",
    Column4: "mohd.aqil77@gmail.com",
    Column5: 9084632031,
    Column6: "HALDWANI",
    Column7: "UTTARAKHAND",
    Column8: "ALM",
    Column9: "ACTIVE",
    Column10:
      "DoorNo/House No/Flat No/Name : ROOM NO G9 PG HOSTEL GMC HALDWANI , Lane/Street/Village : NAINITAL UTTARAKHAND , Area/Mandal/Land Mark : , State : UTTARAKHAND , District : NAINITAL , City : HALDWANI , Pincode : 263139 , Post Office : HALDWANI ",
  },
  {
    slNo: 241,
    Column2: "A4861/A",
    Column3: "DR. ASHOK KUMAR",
    Column4: "dr.tamtaashok@gmail.com",
    Column5: 9456700055,
    Column6: "HALDWANI",
    Column7: "UTTARAKHAND",
    Column8: "ALM",
    Column9: "ACTIVE",
    Column10:
      "DoorNo/House No/Flat No/Name : ROOM NO. 90 , SR HOSTEL , Lane/Street/Village : HALDWANI , Area/Mandal/Land Mark : , State : UTTARAKHAND , District : NAINITAL , City : HALDWANI , Pincode : 263139 , Post Office : ",
  },
  {
    slNo: 242,
    Column2: "K2831/A",
    Column3: "DR. KEERTHANA S ",
    Column4: "Keerthanareddy96.kr@gmail.com",
    Column5: 8660645518,
    Column6: "HALDWANI",
    Column7: "UTTARAKHAND",
    Column8: "ALM",
    Column9: "ACTIVE",
    Column10:
      "DoorNo/House No/Flat No/Name : ROOM NO. F17 ,PG GIRLS HOSTEL,GMC ,HALDWANI , Lane/Street/Village : GMC CAMPUS , Area/Mandal/Land Mark : GMC CAMPUS MANDIR , State : UTTARAKHAND , District : NAINITAL , City : HALDWANI , Pincode : 263139 , Post Office : HALDWANI ",
    Column11: "Mob no.- 8722686989",
  },
  {
    slNo: 243,
    Column2: "S7490/A",
    Column3: "DR. SAKSHI",
    Column4: "divyaratan30@gmail.com",
    Column5: 9410340248,
    Column6: "HALDWANI",
    Column7: "UTTARAKHAND",
    Column8: "ALM",
    Column9: "ACTIVE",
    Column10:
      "DoorNo/House No/Flat No/Name : H.NO.5/726 WARD 19 , Lane/Street/Village : MALLA GORAKHPUR , Area/Mandal/Land Mark : HALDWANI , State : UTTARAKHAND , District : NAINITAL , City : HALDWANI , Pincode : 263139 , Post Office : HALDWANI",
  },
  {
    slNo: 244,
    Column2: "N2193/A",
    Column3: "DR. NEHA CHAUHAN",
    Column4: "atarsingh9410@gmail.com",
    Column5: 8427764806,
    Column6: "HALDWANI",
    Column7: "UTTARAKHAND",
    Column8: "ALM",
    Column9: "ACTIVE",
    Column10:
      "DoorNo/House No/Flat No/Name : S 5 SECOND FLOOR , Lane/Street/Village : GMC HALDWANI , Area/Mandal/Land Mark : SUSHEELA TIWARI HOSPITAL , State : UTTARAKHAND , District : DEHRADUN , City : HALDWANI , Pincode : 263139 , Post Office : HALDWANI POST OFFICE",
    Column11: "Mob no,-8527764806",
  },
  {
    slNo: 245,
    Column2: "K2946/A",
    Column3: "DR. KRITIKA GARG",
    Column4: "akashgrg07@gmail.com",
    Column5: 9927974817,
    Column6: "HALDWANI",
    Column7: "UTTARAKHAND",
    Column8: "ALM",
    Column9: "ACTIVE",
    Column10:
      "DoorNo/House No/Flat No/Name : 15 , Lane/Street/Village : BANGALI ROAD , Area/Mandal/Land Mark : PANCHAYTI NIRWANI AAKHADA , State : UTTARAKHAND , District : HARIDWAR , City : HARIDWAR , Pincode : 249401 , Post Office : KHANKHAL",
    Column11: "email id-kritikagarg007@gmail.com ; mob no-7302694817",
  },
];
export default MembersData;
