import React, { useState } from "react";
import FirstSection from "../Parts/FirstSection/FirstSection";
import SecondSection from "../Parts/SecondSection/SecondSection";
import Members from "./Members/Members";
import UpcommingEvent from "../Parts/UpcommingEvent/UpcommingEvent";
function Home() {
  const [showPDF, setShowPDF] = useState(false);

  const handleClick = () => {
    setShowPDF(true);
  };
  return (
    <>
      <FirstSection />
      <SecondSection />
      <UpcommingEvent />
      {/* <Members /> */}
      {/* <div style={{ height: "100px" }}>
        <a
          href="./assets/Scientific Program.pdf"
          // target="_blank"
          rel="noreferrer"
        >
          View pdf
        </a>
        <a
          href="./assets/Brochure cme july 2023 final.pdf"
          // target="_blank"
          rel="noreferrer"
        >
          View pdf
        </a>
      </div> */}
    </>
  );
}

export default Home;
