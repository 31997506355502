import React from "react";
import "./Header.css";
import { useState, useEffect } from "react";
import Hamburger from "hamburger-react";
import { Link } from "react-router-dom";
import Humburgan from "../Sidebar/Humburgan";
const Header = () => {
  const [showhunber, setShowhunber] = useState(false);
  return (
    <>
      <div className="first-navbar">
        <div className="first-navbar-container">
          <div>
            <img className="logo" src="assets/isa-icon.png" />
          </div>
          <div>
            <img className="logo logo2" src="/assets/logo (1).png" />
          </div>
          <div>
            <h1 className="header-heading">
              Indian Society Of Anaesthesiologists
            </h1>
            <p className="header-pera">Haldwani City Branch</p>
          </div>
          <div>
            <img className="logo logo1" src="/assets/logo (1).png" />
          </div>
          <div className="hamburgar-box">
            {/* <a
              href="#"
              className="hamburgar-icon"
              onClick={() => setShowhunber(!showhunber)}
            >
              <Hamburger color="#ffffff" />
            </a> */}
            <div>
              <Humburgan />
            </div>
          </div>
        </div>

        {/* <div className={showhunber ? "sidebar" : "nav-content1"}>
          <div>
            <ul className="sidebar-ul">
              <Link to="/">
                <li>HOME</li>
              </Link>
              <li>About ISA HCB</li>
              <Link to="/members">
                <li>Members</li>
              </Link>
              <li>Resources</li>
              <Link to="/programs">
                <li>Programes</li>
              </Link>
              <li>Gallery</li>
            </ul>
          </div>
        </div> */}
      </div>
      <div className="second-navbar">
        <ul className="header-ul">
          <Link to="/" className="link">
            <li>HOME</li>
          </Link>
          <li>
            <li class="dropbtn">About ISA HCB</li>

            {/* <div class="dropdown">
              <div class="dropdown-content">
                <a href="#">Link 1</a>
                <a href="#">Link 2</a>
                <a href="#">Link 3</a>
              </div>
            </div> */}
          </li>
          <Link to="/members" className="link">
            <li>Members</li>
          </Link>
          <li>Resources</li>
          <Link to="/programs" className="link">
            <li>Programes</li>
          </Link>
          <Link to="/gallery" className="link">
            <li>Gallery</li>
          </Link>
          <Link to="/contact" className="link">
            <li>Contact</li>
          </Link>
        </ul>
      </div>
    </>
  );
};
export default Header;
