import React from "react";
// import Header from "../Parts/Header/Header";
import Header from "./Parts/Header/Header";
import FirstSection from "./Parts/FirstSection/FirstSection";
import SecondSection from "./Parts/SecondSection/SecondSection";
import Footer from "./Parts/Footer/Footer";
import "../node_modules/bootstrap/dist/css/bootstrap.rtl.min.css";
import "../node_modules/bootstrap/dist/css/bootstrap.css";
import "./App.css";
import Programs from "./Parts/Programs/Programs";
import Home from "./Pages/Home";
import Members from "./Pages/Members/Members";
import Contact from "./Pages/Contact/Contact";
import Gallery from "./Pages/Gallery/Gallery";
import {
  BrowserRouter as Router,
  Link,
  Route,
  Routes,
  Switch,
  useLocation,
} from "react-router-dom";
function App() {
  return (
    <>
      <Router>
        <Header />
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/programs" element={<Programs />} />
          <Route path="/members" element={<Members />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/gallery" element={<Gallery />} />
        </Routes>

        <Footer />
      </Router>

      {/* <FirstSection />
      <SecondSection />
      <Programs /> */}
    </>
  );
}

export default App;
