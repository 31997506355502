import React from "react";
import "./SecondSection.css";
import { useState, useEffect } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Card from "../../Component/Card/Card";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper";

const SecondSection = () => {
  return (
    <>
      <div className="SecondSection-container">
        <div className="SecondSection-main-container">
          <Swiper
            slidesPerView={1}
            spaceBetween={10}
            pagination={{
              clickable: true,
            }}
            breakpoints={{
              640: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 30,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 40,
              },
              1440: {
                slidesPerView: 4,
                spaceBetween: 50,
              },
            }}
            modules={[Pagination]}
            className="mySwiper"
          >
            <SwiperSlide>
              {" "}
              <div>
                <Card
                  name="Dr. Madan Singh Arya"
                  title="President"
                  image="assets/president.JPG"
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              {" "}
              <div>
                <Card
                  name="Dr. Kavita Pokharia"
                  title="Secretary"
                  image="assets/secretery.JPG"
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              {" "}
              <div>
                <Card
                  name="Dr. Kaushal Pandey"
                  title="Treasurer"
                  image="assets/trasurer.JPG"
                />
              </div>
            </SwiperSlide>{" "}
            <SwiperSlide>
              {" "}
              <div>
                <Card
                  name="Dr.Abhishek Nautiyal"
                  //   title="President"
                  image="assets/dr.JPG"
                />
              </div>
            </SwiperSlide>{" "}
            <SwiperSlide>
              {" "}
              <div>
                <Card
                  name="Dr. Charul Jakwal"
                  //   title="President"
                  image="assets/dr1.JPG"
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              {" "}
              <div>
                <Card
                  name="Dr. Sikha Singh"
                  //   title="President"
                  image="assets/dr2.JPG"
                />
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </>
  );
};
export default SecondSection;
