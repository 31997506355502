import React from "react";
const UpcommingEvent = () => {
  return (
    <>
      <div className="SecondSection-container">
        <div className="SecondSection-main-container">
          <h2 style={{ textAlign: "center", marginTop: "30px" }}>
            Our Upcoming Event
          </h2>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "20px",
              marginTop: "50px",
            }}
          >
            <div style={{ display: "flex", gap: "20px", marginLeft: "20px" }}>
              <img style={{ width: "125px" }} src="./assets/nunew_e0.gif" />
              <a
                href="./assets/_ISA NATIONAL CME CUM WORKSHOP SCIENTIC PROGRAM.pdf"
                rel="noreferrer"
                style={{
                  // color: "black",
                  fontSize: "25px",
                  fontWeight: "600",
                }}
              >
                {/* <p
                  style={{
                    // color: "black",
                    fontSize: "25px",
                    fontWeight: "600",
                  }}
                > */}
                Scientific Program
                {/* </p> */}
              </a>
            </div>
            <div style={{ display: "flex", gap: "20px", marginLeft: "20px" }}>
              <img style={{ width: "125px" }} src="./assets/nunew_e0.gif" />
              <a
                href="./assets/ISA National Sponsored CME-cum-Workshop(1st & 2nd July 2023)- Brochure final.pdf"
                rel="noreferrer"
                style={{
                  // color: "black",
                  fontSize: "25px",
                  fontWeight: "600",
                }}
              >
                {/* <p
                  style={{
                    // color: "black",
                    fontSize: "25px",
                    fontWeight: "600",
                  }}
                > */}
                Brochure cme
                {/* </p> */}
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default UpcommingEvent;
