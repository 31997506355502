import React from "react";
import "./Card.css";
function Card({ name, title, image }) {
  return (
    <>
      <div
        class="card"
        style={{ width: "18rem", borderRadius: "20px", height: "295px" }}
      >
        <img src={image} class="card-img-top" alt="" />
        <div class="card-body">
          <h5 class="card-title">{name}</h5>
          <p class="card-text" style={{ color: "#5c5959", fontWeight: "800" }}>
            {title}
          </p>
          {/* <a href="#" class="btn">Know More</a> */}
        </div>
      </div>
    </>
  );
}

export default Card;
