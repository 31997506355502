import React from "react";
import "./FirstSection.css";
import { useState, useEffect } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
const FirstSection = () => {
  return (
    <>
      <Carousel
        autoPlay={true}
        showThumbs={false}
        showStatus={false}
        infiniteLoop={true}
      >
        <div>
          <img
            className="carousel-image"
            height={600}
            src="/assets/IMG-20230515-WA0128.jpg"
          />
        </div>
        <div>
          <img
            className="carousel-image"
            height={600}
            src="/assets/IMG-20230519-WA0004.jpg"
          />
        </div>
        <div>
          <img
            className="carousel-image"
            height={600}
            src="/assets/IMG-20230521-WA0034.jpg"
          />
        </div>
        <div>
          <img
            className="carousel-image"
            height={600}
            src="/assets/IMG-20230521-WA0054.jpg"
          />
        </div>
        <div>
          <img
            className="carousel-image"
            height={600}
            src="/assets/IMG-20230524-WA0035.jpg"
          />
        </div>
        <div>
          <img
            className="carousel-image"
            height={600}
            src="/assets/DSC_7247.JPG"
          />
        </div>
      </Carousel>
    </>
  );
};
export default FirstSection;
