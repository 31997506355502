import React from "react";
import "./Members.css";
import MembersData from "./MembersData";
function Members() {
  //   console.log(MembersData);
  return (
    <>
      <div className="program-container table-responsive">
        <table class="table table-bordered table-responsive">
          <thead>
            <tr>
              <th scope="col">SL No</th>
              <th scope="col">ISA No</th>
              <th scope="col">Name</th>
              <th scope="col">Email ID</th>
              <th scope="col">Mobile</th>
              <th scope="col">City Branch</th>
              <th scope="col">State Branch</th>
              <th scope="col">Member Type</th>
              <th scope="col">Status</th>
              <th scope="col">Current Address</th>
              <th scope="col">Remarks</th>
            </tr>
          </thead>
          <tbody>
            {MembersData &&
              MembersData.map((item) => {
                return (
                  <tr>
                    <th scope="row">{item.slNo}</th>
                    <td>{item.Column2}</td>
                    <td>{item.Column3}</td>
                    <td>{item.Column4}</td>
                    <td>{item.Column5}</td>
                    <td>{item.Column6}</td>
                    <td>{item.Column7}</td>
                    <td>{item.Column8}</td>
                    <td>{item.Column9}</td>
                    <td>{item.Column10}</td>
                    <td>{item.Column11}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default Members;
