import React from "react";
import "./Sidebar.css";
import Sidebar from "./Sidebar";

const Humburgan=()=> {
  return (
    <>
  <div id="App1">
      <Sidebar pageWrapId={"page-wrap"} outerContainerId={"App"} />

      <div id="page-wrap"></div>
    </div>
    </>
  
  )
};

export default Humburgan;